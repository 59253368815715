import { createSlice } from "@reduxjs/toolkit";
import bukhara from '../../../resources/bukhara.jpg'
import bukhara2 from '../../../resources/bukhara2.jpg'
import xiva from '../../../resources/khiva2.jpg'
import xiva2 from '../../../resources/xiva2.jpg'
import tash from '../../../resources/tashkent.jpg'
import tash2 from '../../../resources/tashkent.png'
import vino from '../../../resources/vino.png'
import donior from '../../../resources/donior.png'
import bumaga from '../../../resources/bumaga.png'
import teatr from '../../../resources/teatr.png'
import plov from '../../../resources/plov.png'
import labiXauz from '../../../resources/labiXauz.png'
import magoki from '../../../resources/magoki.png'
import kalyan from '../../../resources/kalyan.png'
import kupal from '../../../resources/kupal.png'
import ark from '../../../resources/ark.png'
import boloXauz from '../../../resources/boloXauz.png'
import samanid from '../../../resources/samanid.png'
import ayub from '../../../resources/ayub.png'
import chorMinor from '../../../resources/chorMinor.png'
import moxiXosa from '../../../resources/moxiXosa.png'
import boy from '../../../resources/boy.png'
import xammam from '../../../resources/xammam.png'
import nakshband from '../../../resources/nakshband.png'
import kuynaArk from '../../../resources/kuynaArk.png'
import aminXan from '../../../resources/aminXan.png'
import kalta from '../../../resources/kalta.png'
import pyatnitsa from '../../../resources/pyatnitsa.png'
import paxlavan from '../../../resources/paxlavan.png'
import tashX from '../../../resources/tash.png'
import islamXoja from '../../../resources/islamXoja.png'
import akSaray from '../../../resources/akSaray.png'
import tilavat from '../../../resources/tilavat.png'
import saodat from '../../../resources/saodat.png'
import chorsu from '../../../resources/chorsu.png'
import imamX from '../../../resources/imamX.png'
import mXimam from '../../../resources/mXimam.png'
import barakX from '../../../resources/barakX.png'
import kukeldash from '../../../resources/kukeldash.png'
import gosmuzeyP from '../../../resources/gosmuzeyP.png'
import amirT from '../../../resources/amirT.png'
import shaxrisabs from '../../../resources/shaxrisabs.png'
import samarkand from '../../../resources/samarkand.jpg'
import gurEmir from '../../../resources/gurEmir.png'
import registan from '../../../resources/registan.png'
import Shaxi from '../../../resources/Shaxi.png'
import siyab from '../../../resources/siyab.png'
import bibi from '../../../resources/bibi.png'
import ulugbek from '../../../resources/ulugbek.png'

const initialState = {
    tours: [
        {
            id: 1,
            title: "Tour in Samarkand",
            body: "Samarkand ist eine der berühmtesten historischen Städte Usbekistans. Dies ist eine der ältesten Städte der Welt, die archäologischen Daten zufolge in der Mitte des 8. Jahrhunderts v. Chr. gegründet wurde. (Altes Marakanda). Einst fuhren hier Karawanen der Großen Seidenstraße vorbei. Seit mehr als zwei Jahrtausenden ist die Stadt ein wichtiger Punkt auf der Großen Seidenstraße zwischen China und Europa. Samarkand war die Hauptstadt des Reiches von Amir Timur (Tamerlane). Timurs Enkel Ulugbek machte die Stadt zum Zentrum des wissenschaftlichen und kulturellen Lebens des mittelalterlichen Ostens. Hier sind die Baudenkmäler des Mittelalters erhalten geblieben, von denen viele in die Liste des UNESCO-Weltkulturerbes aufgenommen wurden. Heute ist Samarkand eine Stadt, die die alten Traditionen des Ostens und die Moderne vereint. Dies ist die drittgrößte Stadt Usbekistans, hier leben mehr als eine halbe Million Menschen.",
            price: "70$",
            img: "https://www.advantour.com/img/uzbekistan/images/samarkand.jpg",
            img2: samarkand,
            places: [
                {title:"Mausoleum Gur Emir", img: gurEmir, body: "Gur-Emir „Emirs Grab“ ist das Mausoleum von Tamerlan (Amir Timur), seinem Mentor Mir Sayyid Barak und einigen Mitgliedern von Timurs Familie (Timuriden) in Samarkand. Das 1403 auf Initiative und auf Kosten Timurs errichtete Meisterwerk der persischen Architektur der timuridischen Ära nimmt einen wichtigen Platz in der Geschichte der islamischen Weltarchitektur ein. Der Hauptarchitekt des Mausoleums war ein aus der iranischen Stadt Isfahan stammender Mahmud Isfahani."},
                {title:"Registan-Platz", img: registan, body: "Der Registan-Platz ist ein Platz im Zentrum von Samarkand. „Registan“ war die Bezeichnung für die Hauptplätze in den Städten des Nahen Ostens. Der Samarkand-Platz ist aufgrund des berühmten architektonischen Ensembles, das sich auf ihm befindet, der berühmteste Registan-Platz, dessen Zentrum die Ulugbek-Medresse (1417–1420), die Sherdor-Medresse (1619–1636) und die Tilla-Kari-Medresse (1646–1660) sind. Das Ensemble aus drei Medressen ist ein einzigartiges Beispiel städtebaulicher Kunst und ein wunderbares Beispiel für die architektonische Gestaltung des Hauptplatzes der Stadt. Es ist eines der leuchtendsten Beispiele islamischer Architektur. "},
                {title:"Bibi-Moschee – Khanym", img: bibi, body: "Die Bibi-Khanym-Moschee (wörtlich „ältere Prinzessin“) ist ein architektonisches Denkmal aus den Jahren 1399-1404 in Samarkand, die grandiose Dommoschee von Tamerlan, reich verziert mit Fliesen, geschnitztem Marmor und Wandgemälden. Ende des 20. Jahrhunderts teilweise aus Ruinen restauriert. Die Moschee wurde im Auftrag von Tamerlane nach seinem siegreichen Feldzug in Indien errichtet. Der Standort der zukünftigen Moschee wurde von Timur selbst ausgewählt. Am Bau waren Handwerker aus verschiedenen Ländern beteiligt: ​​Indien, Iran, Khorezm, die Goldene Horde. Im September 1404 war der Hauptteil der Anlage bereits fertiggestellt. Im Innenhof der Moschee konnten 10.000 Menschen gleichzeitig beten."},
                {title:"Siyab-Basar", img: siyab, body: "Eine der Hauptattraktionen von Samarkand ist der riesige, laute, duftende und verlockende Siab-Basar. Die ganze Welt! Man sucht eine Minute – und schon sind ein paar Stunden vergangen. Lass uns einen Blick darauf werfen!!! Der Markt oder, wie man in Usbekistan sagt, der Basar, ist die Seele der Stadt, der Ort, an dem ihr Herz am meisten schlägt, der Ort, an dem die Stadt vor Ihnen so real erscheint, wie die Stadtbewohner sie sehen. Der Basar in Samarkand ist ein besonderer Ort. Erstens ist es riesig – es umfasst eine Fläche von mehreren Hektar. Zweitens liegt es mitten im Zentrum, nur wenige Schritte von den Hauptattraktionen entfernt, sodass kaum ein Tourist daran vorbeikommen kann. Drittens behält der Basar trotz aller touristischen Attraktivität seine echte, orientalische, usbekische Seele."},
                {title:"Observatorium Ulugbek", img: ulugbek, body: "Das Ulugbek-Observatorium ist eines der bedeutendsten mittelalterlichen Observatorien, das Ulugbek zwischen 1424 und 1428 auf dem Kuhak-Hügel in der Nähe von Samarkand errichtete. Die Überreste des Observatoriums wurden 1908 vom Archäologen V. L. Vyatkin gefunden und erforscht."},
                {title:"Nekropole Shakhi - Zinda", img: Shaxi, body: "Shakhi-Zinda „der lebende König“ ist ein Denkmal mittelalterlicher Architektur in Samarkand, ein Ensemble von Mausoleen des Karachaniden- und Timuriden-Adels. Der uns überlieferte Komplex besteht aus elf Mausoleen, die im XIV.-XV. Jahrhundert nacheinander aneinandergefügt wurden."},
            ],
            extraTitle: "Was Sie außerdem in Samarkand sehen können",
            extraPlaces: [
                {title:"Mausoleum des Heiligen Daniel oder Khoja Donier", img: donior, body: "Das Mausoleum von Khoja Doniyor ist ein Mausoleum in Samarkand über dem Grab eines der Heiligen. Legendären Informationen aus dem 18. Jahrhundert zufolge wurde im Mausoleum die Asche des alttestamentlichen biblischen Propheten Daniyar (Daniel) beigesetzt, dessen Überreste nach Samarkand gebracht und vom zentralasiatischen mittelalterlichen Feldherrn und Eroberer Amir Timur (Tamerlan) begraben wurden )."},
                {title:"Museum der Weinherstellung im Winzavod im. Chowrenko", img: vino, body: "Samarkand, bekannt für seine historischen Sehenswürdigkeiten, kann mit seinen Museen überraschen, unter denen das über 150 Jahre alte Museum für Weinherstellung einen besonderen Platz einnimmt. Hier finden Weinproben statt. In Samarkand wurde eine hervorragende Rebsorte angebaut, die sich durch einen hohen Zuckergehalt auszeichnete. Russischer Winzer und Geschäftsmann D.M. Filatov eröffnete das erste Weingut in Usbekistan. In 15 Jahren Arbeit brachte er die Pflanze auf Weltniveau – die gezüchtete Weinsorte „Biishty“ (was übersetzt „Paradies“ bedeutet) erhielt auf der Internationalen Ausstellung für Weine und Cognacs in Paris eine Goldmedaille, woraufhin Sammler aus aller Welt teilnahmen Menschen aus der ganzen Welt kamen wegen dieses erstaunlichen Weins nach Samarkand. "},
                {title:"Samarkand-Papier - Manuelle Produktion in der Papierfabrik „Meros“", img: bumaga, body: "Heute ist im Dorf Konigil in der Nähe von Samarkand die Papierfabrik Meros tätig, deren Gründer die berühmten Meister, die Brüder Mukhtarov, sind. Dank ihrer Bemühungen wurde die alte Tradition der Papierherstellung mit alten Samarkand-Technologien in der örtlichen Fabrik wiederbelebt. Interessanterweise erfolgt die gesamte Produktion von Hand und jeder Gast der Fabrik kann die Papierherstellung persönlich beobachten."},
                {title:"Historisches Kostümtheater „El Merosi“", img: teatr, body: "Das historische Kostümtheater „El Merosi“ („Volkserbe“) ist seit 2005 in Samarkand tätig. Heute macht Sie das Theater „El Merosi“ auf Kostüme verschiedener historischer Epochen aufmerksam, die uns in der Sprache der Ornamente, Farben und Formen viele geheime Geheimnisse und Gesetze der Schönheit der Volkskunst enthüllen. Dem Glauben zufolge isoliert das Kostüm eine Person nicht nur von der äußeren Umgebung, sondern stärkt sie auch mit Hilfe magischer Handlungen, die oft in Zeichnungen, Ornamenten usw. verschlüsselt sind. Im Samarkander historischen Kostümtheater „El Merosi“ können Sie das Leben, die Lebensweise, Bräuche und Traditionen der Bewohner unserer Region von der Antike bis zur Gegenwart kennenlernen. Hier sind die Kostüme verschiedener historischer Epochen."},
                {title:"Meisterkurs zum Kochen von Samarkand-Pilaw", img: plov, body: "Pilaw ist eine Visitenkarte Usbekistans! Aber sie bereiten es in verschiedenen Teilen unseres Landes auf unterschiedliche Weise zu. Wir empfehlen Ihnen, Samarkand-Pilaw nach unserem Videorezept zuzubereiten. Die Besonderheit des Pilaw nach Samarkand-Art besteht darin, dass er beim Servieren nicht gerührt, sondern schichtweise auf eine Schüssel gelegt wird. Dadurch können Sie das Verhältnis von Reis, Karotten und Fleisch, das in den Löffel gelangt, wählen und so unterschiedliche Geschmackserlebnisse erzielen."},
            ]
        },
        {
            id: 2,
            title: "Tour in Buchara",
            body: "Buchara ist eine Oasenstadt, die größte Siedlung, mitten in der Wüste. Die einst an der Großen Seidenstraße gelegene Stadt ist eine der ältesten – ihre Geschichte reicht über 2500 Jahre zurück. Buchara verkörpert die jahrhundertealte Geschichte alter Traditionen mit dem Islam. Eine Stadt mit einer unglaublichen Atmosphäre der Weisheit, Heiligkeit und Lehre. Sobald man in dieser Stadt ist, fühlt man sich wie in einer anderen Welt und auf einem anderen Planeten. Eine unglaubliche Mischung aus kulturellen, religiösen und ethnischen Zivilisationen machte Buchara zu einer Stadt mit unauslöschlichen Eindrücken.",
            price: "70$",
            img: bukhara,
            img2: bukhara2,
            places: [
                {title:"Ensemble Lyabi - Hauz", img:labiXauz, body: "Die antike Stadt bewahrt noch immer jahrhundertealte Traditionen und erstaunliche Rituale. Buchara wird oft als die Säule des Islam bezeichnet, da sich hier eine große Anzahl von Moscheen, Minaretten und muslimischen Gräbern konzentriert. Alte Gebäude erinnern noch heute an die Ereignisse, die vor Dutzenden von Jahrhunderten auf diesem Land stattfanden. Aus diesem Grund haben die Sehenswürdigkeiten von Buchara einen besonderen Geschmack. Das Stadtmuseum zieht wie ein Magnet Reisende aus aller Welt an, die das orientalische Märchen unter freiem Himmel betrachten möchten. Das Lyabi-Khauz-Ensemble ist der zentrale Platz der Stadt. Der Lyabi-Hauz-Platz ist immer überfüllt, Gäste der Stadt kommen hierher, Anwohner mit Kindern – dies ist ein idealer Ort zum Spazierengehen. Es war einmal ein belebtes Einkaufsviertel auf dem Gelände von Lyabi-Khauz. Heute befindet sich hier eines der ältesten und schönsten Architekturensembles aus dem 16. und 17. Jahrhundert."},
                {title:"Magoki-Moschee – Attori", img:magoki , body: "Die Magoki-Attori-Moschee ist ein Denkmal mit einer interessanten Geschichte. Die Magoki-Attori-Moschee wurde an der Stelle eines zerstörten heidnischen Tempels errichtet. Ein Großbrand zu Beginn des 10. Jahrhunderts zerstörte das Gebäude fast vollständig, doch die Behörden beschlossen, den Tempel zu restaurieren und nach einem ähnlichen Plan eine neue Moschee zu errichten. Das Können der Architekten zeigte sich darin, dass sie Ziegel nicht nur als Baumaterial, sondern auch als Veredelungsmaterial verwendeten."},
                {title:"Handelskuppeln von Buchara", img: kupal, body: "Buchara war nicht nur für die Fülle an Medressen, sondern auch für die Zahl der Karawansereien berühmt. Kreuzungen, an denen am meisten verhandelt wurde, waren zum Schutz vor Sonne und Regen mit Kuppeln bedeckt – keine andere Stadt an der Großen Seidenstraße bot einen solchen Service. Diese mittelalterlichen Einkaufszentren stehen noch heute, ohne ihren Zweck zu ändern. Ein Spaziergang durch die Buchara-Handelsräume ist weniger ein Shopping- als vielmehr ein kulturelles Erlebnis: Man kann Stunden damit verbringen, Roben und Schädelkappen durchzugehen, Seidenteppiche und Susani zu glätten, bemalte Schalen und ziseliertes Geschirr, Silberschmuck und zottelige Pelzmützen zu betrachten. "},
                {title:"Ensemble Poi - Kalyan", img:kalyan, body: "In Buchara stehen sich Madrasas und Moscheen paarweise gegenüber – diese alte zentralasiatische Architekturtechnik wird Kosch genannt. Es entsteht ein unglaublicher Stereoeffekt: Wenn man sich zwischen zwei reich verzierten Bogenfassaden befindet, fühlt man sich in einem Kaleidoskop verloren. Poi-Kalyan („Fuß des Großen“) ist vielleicht der schönste Buchara-Kosh. Auf der einen Seite steht eine 500 Jahre alte Juma-Kathedrale mit einer riesigen azurblauen Kuppel – die zweitgrößte in Zentralasien, sie bietet Platz für 12.000 Menschen. Gegenüber der Medresse Miri Arab prangt."},
                {title:"Крепость Арк", img:ark, body: "Die Festung Ark ist das Herz von Buchara und der Ausgangspunkt ihrer gesamten langen Geschichte. Sie steht auf einem hohen künstlichen Hügel, der von Backsteinmauern umgeben ist. Der Legende nach wurde der erste Palast an dieser Stelle vom Helden des alten iranischen Epos Siyavush erbaut, um das Herz der Tochter von König Afrasiab zu gewinnen: Er hatte vom schädlichen Herrscher die Erlaubnis erhalten, so viel Land einzunehmen, wie passte Bei einem Stierfell schnitt der schlaue junge Mann die Haut in Streifen und band sie zusammen. Die Schnur reichte aus, um einen riesigen Umkreis abzusperren."},
                {title:"Moschee Bolo Hauz", img:boloXauz, body: "Die Bolo-Khauz-Moschee ist die Lieblingsmoschee der örtlichen Herrscher. Im 18. Jahrhundert erlebte das Khanat Buchara einen allgemeinen Niedergang der Wirtschaft und Kultur. Trotzdem beschloss der damals regierende Emir, eine Moschee zu bauen, um darin mit seinem Volk zu beten. Die Moschee erwies sich als unglaublich schön und reich verziert. Ein luxuriöses Ensemble mit vielen Säulen und erstaunlichen Wandgemälden spiegelt sich im Wasser eines kleinen Teiches – Hauz. Die hohen Decken sind mit Schnitzereien und exquisiten Ornamenten verziert. Die Herrscher der Stadt kamen oft zu unterschiedlichen Zeiten hierher, um zu beten."},
                {title:"Mausoleum der Samaniden", img:samanid, body: "Ein herausragendes Denkmal mittelalterlicher Architektur, erbaut an der Wende vom 9. zum 10. Jahrhundert, war lange Zeit vollständig unter der Kulturschicht verborgen und wurde erst zu Beginn des 20. Jahrhunderts entdeckt und restauriert. Das Grab der alten Herrscher Bucharas aus der Samaniden-Dynastie steht in einem Park, der auf dem Gelände eines ehemaligen Friedhofs angelegt wurde. Das kleine kubische Gebäude beeindruckt durch die Komplexität des Dekors und die Spitzenmuster aus einfachen Backsteinen. Ihr Muster ändert sich je nach Höhe der Sonne über dem Horizont."},
                {title:"Mausoleum von Chashma - Ayub", img:ayub, body: "Einer der wichtigsten Mazars von Buchara – heilige Orte für Muslime, zu denen Pilgerfahrten unternommen werden. Der Legende nach entstand die hier gelegene heilige Quelle durch den Schlag des Stabes des Propheten Hiob (Ayub). Mit dem Bau des Mausoleums wurde im 12. Jahrhundert begonnen, später wurde es immer wieder umgebaut und auf dem Gelände des Komplexes wurden weitere Gebäude errichtet. In Chashma-Ayub gibt es viele Grabstätten von Theologen und rechtschaffenen Menschen, die ältesten von ihnen sind etwa tausend Jahre alt."},
            ],
            extraTitle: "Was Sie außerdem in Buchara sehen können",
            extraPlaces: [
                {title:"Chor-Moll-Medresse", img: chorMinor, body: "Die ungewöhnlichste Medresse Bucharas liegt außerhalb von Shahristan – in Rabat, dem aktiven Vorort der antiken Stadt. Sein inoffizieller, aber populärerer Name Chor Minor bedeutet „vier Minarette“: Das Gebäude mit vier Türmen unter blauen Kuppeln ähnelt sowohl einer Spielzeugfestung als auch einem umgedrehten Hocker. Für zusätzliche Eindrücke sorgt auch die Tatsache, dass jeder Turm seine eigenen Muster und Verzierungen hat."},
                {title:"Sitorai Mohi Hosa Palast", img: moxiXosa, body: "Der Name des Landpalastes des letzten Emirs von Buchara bedeutet „den Sternen und dem Mond ähnlich“. Es liegt am Stadtrand von Buchara, 4 km vom Stadtzentrum entfernt: Während des Baus wurde ein Ort ausgewählt, an dem der Wind an den heißesten Tagen für Kühle sorgt. Die Architektur des Ende des 19. Jahrhunderts erbauten Gebäudes vermischt auf unterhaltsame Weise europäische und zentralasiatische Traditionen und die Innenausstattung beeindruckt mit Luxus: venezianische Spiegel, holländische Öfen, Kristallleuchter, Goldstickereien und feine Muster an den Wänden, Marmor Löwen bewachen den Eingang. "},
                {title:"Hausmuseum eines reichen Kaufmanns", img: boy, body: "Im historischen Teil der Altstadt, im Goziyon-Viertel, befindet sich ein reiches Kaufmannshaus. Das Haus gehörte dem Vater von Fayzulla Khodzhaev, Ubaidullo Khodja Kasim Khodzhaev, einem millionenschweren Kaufmann, der Astrachan in Russland, Deutschland und anderen Ländern handelte. Das Haus eines wohlhabenden Kaufmanns ist ein schönes Beispiel der Buchara-Wohnarchitektur des 19. Jahrhunderts. Die Fläche des Hauses beträgt 3 Hektar und besteht aus drei Teilen: Haushalts-, Männer- und Frauenhälfte. Ivans, Wohnzimmer und Wohnräume sind mit prächtigen Schnitzereien und Gemälden auf Holz und Alabaster verziert."},
                {title:"Historische Hammams von Buchara", img: xammam, body: "Das alte Buchara-Bad „Bozori Kord Hammam“ wurde im 16. Jahrhundert erbaut. und hat seine einzigartigen Traditionen und heilenden Eigenschaften immer noch nicht verloren. Ein Besuch im Hamam wird das unvergesslichste Ereignis Ihrer Reise nach Buchara sein. Sie werden in die Welt der alten Traditionen eintauchen und das „echte Buchara“ spüren. Diese Welt entführt Sie weit weg von Souvenirläden, Sehenswürdigkeiten, Reiseführern und Ausflügen und zeigt das wahre Leben der Bucharianer, den echten Alltag, der untrennbar mit der reichen Geschichte dieser Stadt verbunden ist."},
                {title:"Bahauddin Naqshbandi-Komplex", img: nakshband, body: "Der Kult-Gedenkkomplex von Bahauddin Nakshbandi (1318-1389) befindet sich 12 km nordöstlich von Buchara im Dorf Kasri Orifon, wo er geboren und begraben wurde. Khoja Bahauddin Nakshbandi ist eine historische Person, ein berühmter Sufi, ein Zeitgenosse Timurs, der im 14. Jahrhundert lebte. Sein Grab erfreute sich viele Jahre nach seinem Tod großer Beliebtheit, seine Mutter, nahe Verwandte sowie Studenten und Anhänger wurden hier begraben. Der Kult von Bahauddin Nakshbandi erfreute sich im Volk großer Berühmtheit und die Herrscher schenkten dem Ensemble große Aufmerksamkeit."},
            ]
        },
        {
            id: 3,
            title: "Tour in Chiwa",
            body: "Chiwa ist eine der ältesten Städte Usbekistans. Seine Geschichte reicht über zweieinhalbtausend Jahre zurück. Die Stadt Chiwa ist eine der wenigen Städte, die es geschafft hat, ihre Ursprünglichkeit so zu bewahren, wie sie ursprünglich von den Architekten beabsichtigt war. Chiwa ist ein „lebendes“ Denkmal, ein Stadtmuseum. Einer der Legenden zufolge wurde die älteste Stadt vom Sohn des biblischen Noah Shem gegründet. Er begann damit, mitten in der Wüste einen Brunnen zu graben. Karawanen und Händler zogen vorbei, probierten das unglaublich köstliche Wasser und sagten „kheyvak“, also „wie gut“. Und so wurde diese unglaubliche Stadt geboren. Majestätische Gebäude, geschnitzte Türen und Säulen, unglaubliche Schönheit des Gebäudes – das sind Bücher mit verschlüsselten Botschaften aus den Tiefen der Jahrhunderte. Chiwa hat in seiner Geschichte Höhen und Tiefen erlebt. Chiwa war eine der größten Städte des antiken Staates Khorezm. Es gibt Hunderte von Sehenswürdigkeiten in der Stadt, die meisten davon sind religiöse und architektonische Gebäude mit einer reichen Geschichte. Im Jahr 1990 wurde es in die Liste des UNESCO-Weltkulturerbes aufgenommen.",
            price: "70$",
            img: xiva,
            img2: xiva2,
            places: [
                {title:"Yichang - Kala", img: "https://canaan.travel/wp-content/uploads/2020/12/ichan-kala.jpg", body: "Ichan-Kala – „eine Stadt in der Stadt“, ein mittelalterlicher Verteidigungsring in Chiwa. Es war dieser Komplex, der als Ganzes in die UNESCO-Liste aufgenommen wurde, da er perfekt erhalten ist – die Straßen der Stadt sind praktisch unberührt von der Zeit. Ichan-Kala ist von mächtigen Festungsmauern umgeben, die der Legende nach aus demselben Lehm erbaut wurden wie die antike Medina, die vom Propheten Mohammed gegründet wurde. Ichan-Kala ist ein Stadtdenkmal, buchstäblich jedes Gebäude darin ist von historischem und architektonischem Wert."},
                {title:"Zitadelle Kunya-Ark", img: kuynaArk, body: "Die Zitadelle Kunya-Ark ist ein Gebiet, in dem es einen kleinen Zeremonienhof, ein Fundament für die Aufstellung einer Jurte und eines Aivan (eine Nische mit einem Baldachin) gibt. Bis zum Sturz des Chiwa Khan im Jahr 1920 war Kunya-Ark die offizielle Residenz des Herrschers. Der für Gäste interessanteste Ort der Zitadelle ist die Aussichtsplattform, die einen atemberaubenden Blick auf Ichan-Kala bietet."},
                {title:"Muhammad-Amin-Khan-Medresse", img: aminXan, body: "Amin Khan Madrassah ist eine östliche Religionsschule. Vor vielen Jahren lebten hier Studenten, die die Philosophie des Islam studierten. Heute ist es eine einzigartige Kombination aus Geschichte und Moderne. Der Komplex wurde in ein Hotel umgewandelt und heute leben Touristen in den Zellen, in denen einst Studenten lebten. Die ehemalige Sosem-Medresse befindet sich neben dem Gebäude des Kalta-Minaretts, was ihre Beliebtheit nur noch steigert."},
                {title:"Minarett von Kalta", img:kalta , body: "Das Kalta-Minarett ist eines der bekanntesten Symbole der Stadt Chiwa. Es war und bleibt unvollendet – die geplante Höhe betrug 80 Meter, gebaut wurden aber nur 29 Meter. Das Minarett ist mit einem ungewöhnlichen glasierten Mosaik in den Farben Blau und Himmelblau bedeckt. Komplexe Kacheln verleihen dem Gebäude ein ungewöhnliches Aussehen, weshalb das „dicke Minarett“, wie die Einheimischen es nennen, ein beliebter Ort für Fotoshootings ist."},
                {title:"Freitagsmoschee", img:pyatnitsa , body: "Die Freitagsmoschee ist eines der berühmtesten Gebäude der Stadt. Es liegt an der Hauptstraße, die das West- und Osttor verbindet. Zum ersten Mal in schriftlichen Quellen wird es in Quellen aus dem 10. Jahrhundert n. Chr. erwähnt. Das Gebäude ist mit zahlreichen geschnitzten Säulen geschmückt, die in verschiedenen Jahren des Bestehens der Moschee geschaffen wurden. Eingeschnitzte Muster auf den Säulen enthalten oft versteckte Symbole, die die Geschichte der Entstehung der Freitagsmoschee erzählen."},
                {title:"Mausoleum von Pahlavan Mahmud", img:paxlavan , body: "Pakhlavan Mahmud, zu dessen Ehren das Mausoleum errichtet wurde, lebte im 8. Jahrhundert und stammte aus einer Familie eines einfachen Handwerkers. Er war berühmt für seine poetische Begabung, seine große Kraft und seine Fähigkeit, kranke Menschen zu heilen. Das Mausoleum von Mahmud ist ein Gebäude mit einer großen Kuppel. Die Wände sind mit Inschriften in arabischer Schrift bedeckt – es handelt sich um Gedichte von Pahlavan. Im Inneren des Gebäudes befindet sich eine Krypta. Von innen sind die Wände mit komplexen Blumenmustern bedeckt."},
                {title:"Tash-Khauli (Steinpalast)", img:tashX , body: "Der Steinpalast wurde in den 30er und 40er Jahren des 19. Jahrhunderts von Allakuli Khan erbaut. Im Inneren des Gebäudes gibt es einen kleinen Innenhof mit zahlreichen Balkonen, Terrassen und Iwans. Die gefliesten Wände sind mit reichhaltiger durchbrochener Malerei bedeckt, massive Holzsäulen sind mit aufwendigen Holzschnitzereien verziert. Auch die Decken sind mit Gemälden bedeckt. Bei einem Besuch in Tash-Khauli können Sie auch die Räume betreten, in denen einst der Khan lebte. Nach heutigen Maßstäben sind die Räumlichkeiten recht bescheiden – es handelt sich lediglich um zwei Räume, in denen man einen authentischen Thron und andere Möbelstücke und Haushaltsgegenstände des Herrschers sehen kann."},
                {title:"Minarett Islam-Khoja", img:islamXoja , body: "Eine der berühmtesten Sehenswürdigkeiten von Chiwa ist das Islam-Khoja-Minarett. Dieses Meisterwerk der Architektur wurde zu Beginn des 20. Jahrhunderts erbaut. Seine Höhe erreicht 57 Meter und übertrifft das Kalyan-Minarett in Buchara. Der Islam Khoja-Komplex wurde nach dem Wesir von Chiwa, Islam Khoja, benannt. Er diente unter Muhammad Rakhimkhan und seinem Sohn Esfandiyar Khan. Und auf seine Initiative hin entstand dieses architektonische Ensemble."},
            ]
        },
        {
            id: 4,
            title: "Shakhrisabz-Tour",
            body: "Shakhrisabz ist eine der schönsten und farbenfrohsten Städte Usbekistans. Sein aus dem Persischen übersetzter Name bedeutet „Grüne Stadt“, und diese Definition trifft durchaus auf Shakhrisabz zu, da die Stadt tatsächlich im smaragdgrünen Grün der Gärten und Weinberge begraben liegt. Shakhrisabz ist recht klein und liegt nur 80 km südlich von Samarkand, am Fuße der Gissar- und Zeravshan-Gebirge, am Zusammenfluss der Flüsse Aksu und Tankhoz. Über dem Meeresspiegel erhebt sich die Stadt auf 622 Meter. Shakhrisabz ist auch für seine prächtigen historischen und architektonischen Denkmäler bekannt, von denen viele während der Timuriden-Dynastie im 15. Jahrhundert erbaut wurden. Der große Herrscher Timur machte Shakhrisabz zu einer echten zweiten Hauptstadt des Staates, indem er in der Stadt wirklich luxuriöse Gebäude und Bauwerke errichtete, wie Ak-Saray, einen Palast mit schneeweißen Wänden, Dorut-Tillyavat und andere beeindruckende Architekturensembles beeindrucken Gäste bis heute.",
            price: "70$",
            img: shaxrisabs,
            img2: shaxrisabs,
            places: [
                {title:"Ak-Saray-Palast", img: akSaray, body: "Timur träumte davon, in seiner Heimat Kesh (Shakhrisabz) einen viel größeren Palast zu bauen. Der Bau begann im Jahr 1380, also unmittelbar nach der Stärkung der Macht des Autokraten in Maverannahr. Die Bauarbeiten dauerten 24 Jahre, fast bis zum Tod des Eroberers. Der Palast umfasste mehrere Innenhöfe, um die sich Wohnräume und öffentliche Räume befanden. Die Räumlichkeiten waren mit goldenem Azurblau geschmückt, die Fassaden der Gebäude waren mit farbigen Fliesen verkleidet, die Innenhöfe waren mit weißen Platten gepflastert."},
                {title:"Ensemble Dorut – Tilavat", img: tilavat, body: "Im Herzen von Shakhrisabz, neben der Nekropole des Barlas-Adels aus dem XIV.-XV. Jahrhundert, befindet sich das Dorut Tillovat-Ensemble – ein Gedenkkomplex und ein Architekturdenkmal. Der Name des Komplexes wird mit „Haus der Besinnung oder Meditation“ übersetzt und wurde zwischen 1370 und 1371 gegründet. Heute sind drei Gebäude dieses Ensembles zu sehen – die Dommoschee und zwei Mausoleen, vereint durch einen Innenhof."},
                {title: "Ensemble Dorus - Saodat", img: saodat, body:"Im Jahr 1380 begann in Shakhrisabz gleichzeitig mit dem Bau des Ak-Saray-Palastes der Bau eines weiteren monumentalen Ensembles, bekannt als Dorus-Saodat. Vom Dorus-Saodat-Ensemble sind bis heute das Mausoleum von Jahangir und die Krypta von Temur erhalten, in der er nie begraben wurde. Der Grund für den Bau des Gedenkkomplexes war ein trauriges Ereignis – 1376 starb Temurs ältester Sohn Jahangir plötzlich im zweiundzwanzigsten Jahr seines Lebens, den der Herrscher sehr liebte und darauf vorbereitete, seine Erben zu werden. Die Einwohner von Samarkand trauerten um den unerwarteten Tod des Thronfolgers, „eines gerechten Prinzen, eines tapferen Kriegers, der wie eine Rose auf der Erde aufblitzte“. Der Meister selbst verfiel in eine tiefe Depression."},
            ]
        },
        {
            id: 5,
            title: "Tour in Taschkent",
            body: "Taschkent ist die Hauptstadt Usbekistans, eine Metropole mit mehr als 2,5 Millionen Einwohnern, gesäumt von einem Netz aus geraden und breiten Straßen und Alleen, geschmückt mit „Smaragdgrünen“ von Parks, Plätzen und Gärten, durchzogen von Kristallfäden aus Brunnen ... Diese wunderbare Stadt ist die Verkörperung dieser sehr modernen Eleganz, die vielen Hauptstädten der Welt innewohnt, aber gleichzeitig hat Taschkent als östliche Stadt auch ihren eigenen, einzigartigen Geschmack. Es kombiniert auf exquisite Weise mittelalterliche Gebäude, als wären sie den Seiten antiker orientalischer Märchen entsprungen, elegante europäische Architektur aus der Zeit des turkestanischen Generalgouverneurs, Standard-Betonkästen aus der Sowjetzeit und schließlich funkelnde Hochhäuser Gebäude aus Glas und Beton – die Gebäude moderner Geschäftszentren und Banken – als Zeugnis einer neuen Ära des unabhängigen Usbekistans.",
            price: "70$",
            img: tash2,
            img2: tash,
            places: [
                {title:"Chorsu-Basar", img: chorsu, body: "Der Basar „Eski Zhuva“ oder „Chorsu“ ist einer der größten Basare in Usbekistan und Zentralasien und befindet sich im alten Teil von Taschkent namens „Eski Shahar“, der für die Große Seidenstraße von großer Bedeutung war. Und selbst der Name des Marktes wird aus dem Persischen mit „vier Straßen“ oder „vier Bäche“ übersetzt, da in der Antike an diesem Ort alle Handelswege des Ostens zusammenliefen. Und heutzutage steht der Chorsu-Basar im Zentrum der Kreuzung der vier Haupteinkaufsstraßen von Taschkent. Chorsu ist eine riesige zentrale Kuppel, die von sieben kleineren Kuppeln umgeben ist. Dadurch entsteht ein komplexer Raum mit abwechselnden Innen-, offenen und halboffenen Räumen."},
                {title:"Ensemble Hazrati Imam", img: imamX, body: "Das Khazrati-Imam-Ensemble ist ein religiöser Komplex in Taschkent. Das Ensemble wurde in der Nähe des Grabes des Imams der Stadt Taschkent, eines Wissenschaftlers, eines Experten für Koran und Hadithe, eines der ersten Prediger des Islam in Taschkent, eines Dichters und Handwerkers Khazrati Imam (sein vollständiger Name ist Abu Bakr) errichtet ibn Ismail al-Kaffal ash-Shoshiy). Historischen Quellen zufolge war er auch Schlosser und Schlüsselmacher, wofür er den Spitznamen „Kaffal“ erhielt, was „Schlossmacher“ bedeutet, sprach 72 Sprachen und übersetzte das Alte Testament (Tora) ins Arabische."},
                {title: "Hazrati-Imam-Moschee", img: mXimam, body:"Die Hazrati-Imam-Kathedrale-Moschee wurde 2007 auf Initiative und Projekt des Präsidenten der Republik Usbekistan Islam Karimov errichtet. Die Moschee hat zwei Kuppeln und zwei Minarette mit einer Höhe von 53 Metern. Der innere Teil der Kuppeln ist mit Blattgold verziert. Am Eingang der Moschee steht ein Iwan mit zwanzig geschnitzten Sandelholzsäulen. Die Fensteröffnungen sind so gestaltet, dass sie von Sonnenaufgang bis Sonnenuntergang die Sonnenstrahlen in das Innere der Moschee eindringen lassen."},
                {title: "Barak-Khan-Medresse", img: barakX, body:"Die Barak-Khan-Medresse wurde zwischen 1531 und 1532 erbaut. auf Anweisung von Ulugbeks Enkel Nauruz Ahmed Khan, der den Spitznamen „Barak Khan“ trug, was „Glück“ bedeutet. Im Inneren der Medresse befinden sich zwei Mausoleen – das Mausoleum von Suyunchkhoja Khan, das über der Grabstätte von Suyunchkhoja Khan – dem ersten Herrscher von Taschkent aus der usbekischen Sheibaniden-Dynastie – errichtet wurde. Der Name des zweiten Mausoleums ist Namenlos. Es wurde für Barak Khan gebaut, aber Barak Khan wurde anschließend in Samarkand begraben. Die Türen der Zellen der Barakkhan-Medresse sind mit Elfenbein und Nichteisenmetallen verziert."},
                {title: "Mausoleum von Hadhrat Imam", img: mXimam, body:"Mausoleum von Hadhrat Imam. Im 16. Jahrhundert zu Ehren des Khazrat-Imams (Abu Bakr ibn Ismail al-Kaffal ash-Shoshiy) errichtet. Das Mausoleum oder Grab besteht aus gebrannten Ziegelsteinen, ist mit Majolika verziert und hat eine rechteckige Form mit einer großen blauen Kuppel an der Spitze. Die Fenster des Gebäudes sind mit Ganch Panjara verziert."},
                {title: "Madrasa Kukeldash", img: kukeldash, body:"Eine der größten islamischen Sehenswürdigkeiten Taschkents, die Kukeldasch-Medresse, befindet sich in der Nähe des Chorsu-Platzes. Diese Medresse ist seit langem als Symbol des alten Teils der Hauptstadt bekannt. Im 10. Jahrhundert befand sich hier eines der drei Stadttore. Die Medresse wurde im 16. Jahrhundert während der Herrschaft der Scheibaniden in Taschkent erbaut. Der Bau wurde vom Oberwesir überwacht, der den Spitznamen „Kukaldosh“ trug, was aus dem Türkischen „Milchbruder“ bedeutet. Er stand den Taschkent-Herrschern Barak Khan und Derwisch Khan nahe. Daher stammt auch der ungewöhnliche Name der Medresse."},
                {title: "Staatliches Museum für Angewandte Kunst", img: gosmuzeyP, body:"1937 auf der Grundlage einer temporären Kunsthandwerksausstellung gegründet. Der Ausstellungsbestand des Museums umfasst über 4.000 Exponate, die die Geschichte der dekorativen Künste Usbekistans offenbaren: Holzschnitzerei, Keramik, Prägung, Juwelierkunst, Stickereien, Beispiele der Massenproduktion der lokalen Industrie. Bis zum Beginn des 21. Jahrhunderts befand sich das Museum im ehemaligen Palast des russischen Diplomaten A.A. Polovtsev. Das Gebäude wurde für A.A. Polovtsev von seinem Sekretär M.S. Andreev in seinem Namen vom Taschkenter Kaufmann Ivanov gekauft. Dieses Haus war in Taschkent als „Polovtsevs Haus“ bekannt."},
                {title: "Amir-Timur-Platz", img: amirT, body:"Die Geschichte des Platzes im Zentrum von Taschkent, der heute den Namen des Kommandanten und Gründers eines riesigen mittelalterlichen Reiches – Amir Timur – trägt, begann im 19. Jahrhundert, als Taschkent als Teil des turkestanischen Militärbezirks zum Zentrum wurde Russisches Reich. Es wurde 1882 im Auftrag von General M. Chernyaev gegründet. Der Platz war ein kleiner Park im Zentrum der Stadt, um den sich die Gebäude des Frauen- und Männergymnasiums, des Lehrerseminars und der Staatsbank befanden."},
            ]
        },
        {
            id: 6,
            title: "Tour durch die Städte der Großen Seidenstraße",
            body: "1994 wurde in Samarkand eine Erklärung „Zur Wiederbelebung der Seidenstraße“ verabschiedet. Die Hauptroute durchquert 32 Städte und Gemeinden Zentralasiens. Taschkent, Samarkand, Buchara, Chiwa und die Städte des Fergana-Tals sind Juwelen der Großen Seidenstraße.",
            price: "Reisepreis auf Anfrage",
            img: tash,
            img2: "https://cdn2.tu-tu.ru/image/pagetree_node_data/1/da19747169d87b7b39d0863c38c9bf88/",
            places: [
                {title:"1 Tag Taschkent", img: tash2, body: "Treffen am Flughafen Taschkent, Hotelübernachtung."},
                {title:"Tag 2 Taschkent", img: kukeldash, body: "Um 10.00 Uhr – ein Rundgang durch die „Altstadt“: Khast-Imam-Platz, Barak-Khan-Medresse, Kafal-al-Shashi-Mazar-Mausoleum, Kukeldash-Medresse, der älteste Basar in Taschkent – ​​Chorsu. Besichtigung des modernen Teils von Taschkent: Museum für Angewandte Kunst, Unabhängigkeitsplatz, Amir-Temur-Platz. Übernachtung im Hotel."},
                {title: "Tag 3 Taschkent – ​​Samarkand. Samarkand (Ausflug).", img: registan, body:"Frühstück im Hotel. Transfer nach Samarkand. Um 11:00 Uhr Ausflug durch Samarkand: der berühmteste Platz Zentralasiens – Registan (Ulugbek-Medresse, Sher-Dor-Medresse, Tillya-Kari-Medresse), Gur-Emir-Mausoleum – das Grab des Großen Tamerlan. Nach dem Mittagessen setzen Sie die Tour durch Samarkand fort: die Bibi-Khanum-Moschee, das architektonische Ensemble Shakhi-Zinda und das Ulugbek-Observatorium. Übernachtung im Hotel."},
                {title: "Tag 4 Samarkand – Gijduvan – Buchara. Buchara (Ausflug).", img: labiXauz, body:"Transfer und Hotelübernachtung. Nach dem Mittagessen Besichtigungen in Buchara: Besuch des Lyabi-Khauz-Ensembles, der Kukeldash-Medresse, der Nadir Divanbegi-Medresse, der Handelskuppeln, des Samaniden-Mausoleums, des Chashma Ayub-Grabs, des Bolo-Khauz-Komplexes, der alten Ark-Festung und des Poi-Kalyan-Komplexes. Abendessen im Nationalhaus – ein Meisterkurs zum Kochen von Pilaw. Übernachtung im Hotel."},
                {title: "Tag 5 Buchara", img: ark, body:"Frühstück im Hotel. Fortsetzung der Tour durch das antike Buchara. Freizeit"},
                {title: "Tag 6 Buchara – Chiwa (Bus 440 km).", img: "http://sreda.uz/wp-content/uploads/posts/2015-11/1447435571_img_5433.jpg", body:"Frühstück im Hotel. Um 08:00 Uhr Transfer durch die berühmte Kyzylkum-Wüste entlang des Flusses Amudarya. Picknick auf der Straße. Ankunft in Chiwa. Freizeit, Besuch von Souvenirläden und Werkstätten. Übernachtung im Hotel."},
                {title: "Tag 7 Chiwa (Ausflug).", img: "https://canaan.travel/wp-content/uploads/2020/12/ichan-kala.jpg", body:"Frühstück im Hotel. Um 09:00 Uhr – ein Rundgang durch Chiwa (Ichan-Kala – die Innenstadt): Pakhlavan-Mahmud-Komplex, Kunya-Ark-Festung, Islam-Khoja-Medresse und Minarett, Tash-Chauli-Palast, Alakuli-Khan-Medresse, Juma-Moschee, Minarett und Mohammed-Medresse Aminkhan. Übernachtung im Hotel."},
                {title: "Tag 8 Chiwa – Urgentsch", img: "https://gulaytour.ru/wp-content/uploads/2017/07/1494142.jpg", body:"Transfer zum internationalen Flughafen Urgentsch. Abfahrt von Urgentsch. Ende des Tourprogramms."},
            ]
        }
    ]
}

export const toursSlice = createSlice({
    name: 'toursRU',
    initialState,
    reducers: {}
})

export default toursSlice.reducer